import {
  USER_ERROR,
  USER_REQUEST,
  USER_RESET,
  USER_SET,
  USER_SET_REVENUE,
  USER_SUCCESS,
  USER_UPDATED,
} from './types';

export const initialState = {
  updated: false,
  loading: false,
  error: null,
  data: {},
  revenue: {
    partnerships: {
      currency: 'usd',
      total: 0,
    },
    total: {
      currency: 'usd',
      total: 0,
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case USER_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case USER_RESET:
      return {
        ...initialState,
      };
    case USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case USER_SET:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        loading: false,
      };
    case USER_SET_REVENUE:
      return {
        ...state,
        revenue: action.payload,
        loading: false,
      };
    case USER_UPDATED:
      return {
        ...state,
        loading: false,
        updated: action.payload,
      };
    default:
      return state;
  }
}
