import i18n from 'i18n-js';

import { FetchProvider } from '../../providers';
import { AUTH_ERROR, AUTH_REQUEST, AUTH_SUCCESS } from './types';

export const authError = (error) => ({
  type: AUTH_ERROR,
  error,
});

export const authRequest = {
  type: AUTH_REQUEST,
};

export const authSuccess = {
  type: AUTH_SUCCESS,
};

export const getSignIn =
  (data, history, onSuccess, pathname) => async (dispatch) => {
    dispatch(authRequest);
    try {
      const language = navigator.language || navigator.userLanguage;
      const result = await FetchProvider.fetchPost('auth/signin', {
        ...data,
        language,
      });
      if (result && result.sent) {
        await dispatch(authSuccess);
        onSuccess();
        if (pathname) history.push(pathname);
      }
      return;
    } catch (e) {
      if (!e?.response)
        return dispatch(authError(i18n.t('common.connectionError')));
      if (e?.response?.status === 400)
        return dispatch(authError(i18n.t('screens.signin.error')));

      return dispatch(authError(i18n.t('screens.signin.error')));
    }
  };

export const setSignUp = (data, onSuccess) => async (dispatch) => {
  dispatch(authRequest);
  let geolocation = {};
  try {
    geolocation = await FetchProvider.fetchGetOutside(
      `https://api.ipbase.com/v1/json/?apikey=${process.env.REACT_APP_FREEGEOIP_API_KEY}`
    );
  } catch (error) {
    console.error(error);
  }
  try {
    const language = navigator.language || navigator.userLanguage;
    const result = await FetchProvider.fetchPost('auth/signup', {
      ...data,
      countryCode: geolocation.country_code,
      language,
      timeZone: geolocation.time_zone,
    });
    if (result && result.sent) {
      await dispatch(authSuccess);
      onSuccess();
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(authError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(authError(i18n.t('screens.signup.errorEmail')));
    if (e?.response?.status === 409)
      return dispatch(authError(i18n.t('screens.signup.errorUsername')));

    return dispatch(authError(i18n.t('screens.signup.error')));
  }
};
