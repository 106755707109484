import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { AnalyticsProvider } from '../../../../providers';
import { overlayReset } from '../../../../reducers/Overlay/actions';
import { overlaysReset } from '../../../../reducers/Overlays/actions';
import { userReset } from '../../../../reducers/User/actions';
import { AvatarContainer } from './styles';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let history = useHistory();
  const open = Boolean(anchorEl);

  const onClickAvatar = (event) => {
    event.stopPropagation();
    AnalyticsProvider.event('CLICK_USER');
    setAnchorEl(event.currentTarget);
  };
  const onCloseMenu = () => {
    setAnchorEl(null);
  };
  const onClickChannel = (event) => {
    AnalyticsProvider.event('CLICK_CHANNEL');
    history.push('/channel');
    onCloseMenu();
  };
  const onClickWallet = (event) => {
    AnalyticsProvider.event('CLICK_WALLET');
    history.push('/wallet');
    onCloseMenu();
  };
  const onClickPayoutSettings = (event) => {
    AnalyticsProvider.event('CLICK_PAYOUT_SETTINGS');
    history.push('/payout-settings');
    onCloseMenu();
  };
  const onClickSettings = (event) => {
    AnalyticsProvider.event('CLICK_SETTINGS');
    history.push('/settings');
    onCloseMenu();
  };
  const onClickLogout = async () => {
    const { builderReset } = await import(
      '../../../../reducers/Builder/actions'
    );
    AnalyticsProvider.event('LOGOUT');
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    dispatch(builderReset);
    dispatch(overlayReset);
    dispatch(overlaysReset);
    dispatch(userReset);
    history.push('/dashboard');
  };
  const onClickOcoins = () => {
    AnalyticsProvider.event('CLICK_OCOINS');
    history.push('/ocoins');
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <AvatarContainer onClick={onClickOcoins}>
          <Tooltip title={i18n.t('menu.toolbar.openSettings')}>
            <IconButton
              onClick={onClickAvatar}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar alt={user?.data?.username} src={user?.data?.avatar} />
            </IconButton>
          </Tooltip>
          <Typography ml={1} variant="body1">
            {user?.data?.ocoins}
          </Typography>
          <MonetizationOnIcon fontSize="medium" />
        </AvatarContainer>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onCloseMenu}
        PaperProps={{
          elevation: 8,
          sx: {
            minWidth: 200,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={onClickChannel}>
          <ListItemIcon>
            <VideoCameraBackIcon fontSize="small" />
          </ListItemIcon>
          {i18n.t('menu.toolbar.channel')}
        </MenuItem>
        <MenuItem onClick={onClickWallet}>
          <ListItemIcon>
            <AccountBalanceWalletIcon fontSize="small" />
          </ListItemIcon>
          {i18n.t('menu.toolbar.wallet')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={onClickPayoutSettings}>
          <ListItemIcon>
            <AccountBalanceIcon fontSize="small" />
          </ListItemIcon>
          {i18n.t('menu.toolbar.payoutSettings')}
        </MenuItem>
        <MenuItem onClick={onClickSettings}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {i18n.t('menu.toolbar.settings')}
        </MenuItem>
        <MenuItem onClick={onClickLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {i18n.t('menu.toolbar.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}
