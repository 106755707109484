import io from 'socket.io-client';

let socket;

export const connect = async (overlayId) => {
  socket = io.connect(process.env.REACT_APP_API_URL, {
    query: `overlayId=${overlayId}`,
  });
};

export const disconnect = () => {
  if (!socket) return;

  socket.disconnect();
  socket = null;
};

export const listen = (event, callback) => {
  if (!socket) return;

  socket.on(event, callback);
};

export default {
  connect,
  disconnect,
  listen,
};
