import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';

export const Container = styled.div`
  align-items: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
Container.displayName = 'Container';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 424px;
`;
Form.displayName = 'Form';

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 424px;
`;
Header.displayName = 'Header';

export const useStyles = makeStyles((theme) => ({
  alert: {
    margin: '16px 0 16px',
  },
  link: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    '& strong': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  required: {
    '& strong': {
      color: theme.palette.primary.main,
    },
  },
  submit: {
    height: 56,
    margin: theme.spacing(3, 0, 2),
  },
}));
