import validate from 'validate.js';
import i18n from 'i18n-js';

import CONSTANTS from './constants';

const { USERNAME_REGEX } = CONSTANTS;

validate.validators.checkWhiteSpaces = function (value, options) {
  if (!/\s+/.test(value)) return null;
  return options.message;
};

validate.validators.checkUsername = function (value, options) {
  if (USERNAME_REGEX.test(value)) return null;
  return options.message;
};
validate.validators.isTrue = function (value, options) {
  if (value === true) return null;
  return options.message;
};

export const getBillingDataConstraints = (paymentMethod) => ({
  name: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
  birthDate: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
  address: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
  postCode: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
    format: {
      pattern: '^[a-z0-9][a-z0-9- ]{0,10}[a-z0-9]$',
      flags: 'i',
      message: i18n.t('screens.wallet.postalCodeError'),
    },
  },
  ...(paymentMethod === 'bank-account'
    ? {
        iban: {
          presence: {
            allowEmpty: false,
            message: i18n.t('common.isRequired'),
          },
          format: {
            pattern:
              '^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$',
            flags: 'i',
            message: i18n.t('screens.payoutSettings.ibanError'),
          },
        },
        swift: {
          presence: {
            allowEmpty: false,
            message: i18n.t('common.isRequired'),
          },
          format: {
            pattern: '^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$',
            flags: 'i',
            message: i18n.t('screens.payoutSettings.swiftError'),
          },
        },
      }
    : {
        paypalMe: {
          presence: {
            allowEmpty: false,
            message: i18n.t('common.isRequired'),
          },
          format: {
            pattern: '^(?:https)://paypal.me/.+',
            flags: 'i',
            message: i18n.t('screens.payoutSettings.paypalError'),
          },
        },
      }),
  identityType: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
  identityNumber: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
    length: {
      minimum: 6,
      message: i18n.t('screens.payoutSettings.identityNumberError'),
    },
  },
});

export const getSigninConstraints = () => ({
  email: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
});

export const getSignupConstraints = () => ({
  confirmTerms: {
    isTrue: {
      message: i18n.t('common.isRequired'),
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
    email: {
      message: i18n.t('common.validEmail'),
    },
  },
  username: {
    checkUsername: {
      message: i18n.t('screens.signup.usernameError'),
    },
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
});

export const getUserDataConstraints = () => ({
  email: {
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
    email: {
      message: i18n.t('common.validEmail'),
    },
  },
  username: {
    checkUsername: {
      message: i18n.t('screens.settings.usernameError'),
    },
    presence: {
      allowEmpty: false,
      message: i18n.t('common.isRequired'),
    },
  },
});

const constraints = {
  getBillingDataConstraints,
  getSigninConstraints,
  getSignupConstraints,
  getUserDataConstraints,
};

export default constraints;
