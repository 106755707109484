import i18n from 'i18n-js';

import { AnalyticsProvider, FetchProvider } from '../../providers';
import {
  USER_ERROR,
  USER_REQUEST,
  USER_RESET,
  USER_SET,
  USER_SET_REVENUE,
  USER_SUCCESS,
  USER_UPDATED,
} from './types';

export const userError = (error) => ({
  type: USER_ERROR,
  error,
});

export const userRequest = {
  type: USER_REQUEST,
};

export const userReset = {
  type: USER_RESET,
};

export const userSuccess = (data) => ({
  type: USER_SUCCESS,
  payload: data,
});

export const userSet = (data) => ({
  type: USER_SET,
  payload: data,
});

export const userSetRevenue = (data) => ({
  type: USER_SET_REVENUE,
  payload: data,
});

export const userUpdated = (data) => ({
  type: USER_UPDATED,
  payload: data,
});

export const getMe = (data) => async (dispatch) => {
  dispatch(userRequest);

  const getGeolocation = async () => {
    let geolocation = null;
    try {
      geolocation = await FetchProvider.fetchGetOutside(
        `https://api.ipbase.com/v1/json/?apikey=${process.env.REACT_APP_FREEGEOIP_API_KEY}`
      );
    } catch (error) {
      console.error(error);
    }
    return geolocation;
  };

  try {
    const result = await FetchProvider.fetchGet('user', data);

    if (!result.countryCode) {
      const geolocation = await getGeolocation();
      if (geolocation) {
        result.countryCode = geolocation.country_code;
        result.timeZone = geolocation.time_zone;
      }
    }
    await dispatch(userSuccess(result));
    AnalyticsProvider.setUserId(result?.id, result);
    return;
  } catch (e) {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  }
};

export const getRevenue = () => async (dispatch) => {
  dispatch(userRequest);

  try {
    const result = await FetchProvider.fetchGet('user/revenue');

    if (result) {
      await dispatch(userSetRevenue(result));
    }
    return;
  } catch (e) {
    return dispatch(userError(i18n.t('common.connectionError')));
  }
};

export const updateUserData = (data) => async (dispatch, getState) => {
  dispatch(userRequest);
  try {
    const { user } = getState();
    const userInfo = {
      countryCode: user.data.countryCode,
      timeZone: user.data.timeZone,
    };
    const result = await FetchProvider.fetchPut('user', {
      ...data,
      ...userInfo,
    });
    if (result.updated) {
      await dispatch(userSuccess({ ...user.data, ...data }));
      await dispatch(userUpdated(true));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(userError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(userError(i18n.t('common.incorrectData')));
    if (e?.response?.status === 404)
      return dispatch(userError(i18n.t('screens.settings.userEmailError')));

    return dispatch(userError(i18n.t('screens.settings.userUpdateError')));
  }
};

export const updateBillingData = (data) => async (dispatch, getState) => {
  dispatch(userRequest);
  try {
    const { user } = getState();
    const result = await FetchProvider.fetchPut('user/billing-data', data);
    if (result.updated) {
      await dispatch(userSuccess({ ...user.data, billingData: data }));
      await dispatch(userUpdated(true));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(userError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(userError(i18n.t('common.incorrectData')));
    if (e?.response?.status === 404)
      return dispatch(userError(i18n.t('screens.settings.userNotFound')));

    return dispatch(userError(i18n.t('screens.settings.userUpdateError')));
  }
};

export const updateSocialMedia = (data) => async (dispatch, getState) => {
  dispatch(userRequest);
  try {
    const { user } = getState();
    const result = await FetchProvider.fetchPut('user/social-media', data);
    if (result.updated) {
      await dispatch(userSuccess({ ...user.data, socialMedia: data }));
      await dispatch(userUpdated(true));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(userError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(userError(i18n.t('common.incorrectData')));
    if (e?.response?.status === 404)
      return dispatch(userError(i18n.t('screens.settings.userNotFound')));

    return dispatch(userError(i18n.t('screens.settings.userUpdateError')));
  }
};

export const updateAvatar = (data) => async (dispatch, getState) => {
  dispatch(userRequest);
  try {
    const { user } = getState();
    const userInfo = {
      countryCode: user.data.countryCode,
      timeZone: user.data.timeZone,
    };
    const result = await FetchProvider.fetchPost('user/avatar', {
      ...data,
      ...userInfo,
    });
    if (result.updated) {
      await dispatch(userSuccess({ ...user.data, ...data }));
      await dispatch(userUpdated(true));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(userError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(userError(i18n.t('common.incorrectData')));

    return dispatch(userError(i18n.t('screens.settings.userUpdateError')));
  }
};

export const updateBanner = (data) => async (dispatch, getState) => {
  dispatch(userRequest);
  try {
    const { user } = getState();
    const userInfo = {
      countryCode: user.data.countryCode,
      timeZone: user.data.timeZone,
    };
    const result = await FetchProvider.fetchPost('user/banner', {
      ...data,
      ...userInfo,
    });
    if (result.updated) {
      await dispatch(userSuccess({ ...user.data, ...data }));
      await dispatch(userUpdated(true));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(userError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(userError(i18n.t('common.incorrectData')));

    return dispatch(userError(i18n.t('screens.settings.userUpdateError')));
  }
};

export const setNewApiKey = () => async (dispatch, getState) => {
  dispatch(userRequest);
  try {
    const result = await FetchProvider.fetchPost('user/api-key');
    if (result.updated) {
      const { user } = getState();
      await dispatch(userSuccess({ ...user.data, apiKey: result.apiKey }));
      await dispatch(userUpdated(true));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(userError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(userError(i18n.t('common.incorrectData')));
    if (e?.response?.status === 404)
      return dispatch(userError(i18n.t('screens.settings.userNotFound')));

    return dispatch(userError(i18n.t('screens.settings.userUpdateError')));
  }
};

export const deleteApiKey = () => async (dispatch, getState) => {
  dispatch(userRequest);
  try {
    const result = await FetchProvider.fetchDelete('user/api-key');
    if (result.deleted) {
      const { user } = getState();
      await dispatch(userSuccess({ ...user.data, apiKey: null }));
      await dispatch(userUpdated(true));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(userError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(userError(i18n.t('common.incorrectData')));
    if (e?.response?.status === 404)
      return dispatch(userError(i18n.t('screens.settings.userNotFound')));

    return dispatch(userError(i18n.t('screens.settings.userUpdateError')));
  }
};
