import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import validate from 'validate.js';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import CONSTRAINTS from '../../../../constants/constraints';
import { AnalyticsProvider } from '../../../../providers';
import { authSuccess, getSignIn } from '../../../../reducers/Auth/actions';
import Loading from '../../../Loading';
import { Container, Form, Header, useStyles } from './styles';

const { getSigninConstraints } = CONSTRAINTS;

export default function LoginDialog({ onClose, onSignup, open }) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  let history = useHistory();
  const { state: stateLocation } = useLocation();
  const classes = useStyles();

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onCloseDialog = () => {
    setEmail('');
    setEmailError(null);
    onClose();
  };
  const onSuccessSignin = () => {
    onCloseDialog();
    setOpenSuccessMessage(true);
  };
  const onSubmit = (event) => {
    event.preventDefault();

    setEmailError(null);

    const data = { email };
    const errors = validate(data, getSigninConstraints());
    if (errors && errors.email) {
      setEmailError(errors.email?.[0]);
    } else {
      dispatch(
        getSignIn(data, history, onSuccessSignin, stateLocation?.from?.pathname)
      );
      AnalyticsProvider.event('SIGNIN', {
        label: email,
        email,
      });
    }
  };
  const handleClose = () => {
    dispatch(authSuccess);
  };
  const onClickSignup = () => {
    onCloseDialog();
    onSignup();
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth="md">
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Loading isOpen={auth.loading} />
          <Container>
            <Header>
              <Typography gutterBottom variant="h4">
                {i18n.t('screens.signin.title')}
              </Typography>
              <Typography color="text.secondary" gutterBottom variant="body2">
                {i18n.t('screens.signin.subtitle')}
              </Typography>
              {auth.error && (
                <Alert
                  className={classes.alert}
                  elevation={6}
                  icon={false}
                  onClose={handleClose}
                  severity="error"
                  variant="filled"
                >
                  {auth.error}
                </Alert>
              )}
            </Header>
            <Form>
              <form noValidate>
                <TextField
                  autoComplete="email"
                  autoFocus
                  error={!!emailError}
                  fullWidth
                  helperText={emailError}
                  label={i18n.t('screens.signin.email')}
                  margin="normal"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  value={email}
                  variant="outlined"
                />
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={auth.loading}
                  fullWidth
                  onClick={onSubmit}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {i18n.t('screens.signin.cta')}
                </Button>
                <Grid container justifyContent="center">
                  <Grid item>
                    <div className={classes.link} onClick={onClickSignup}>
                      {i18n.t('screens.signin.signup')}{' '}
                      <strong>{i18n.t('screens.signin.signup2')}</strong>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Form>
          </Container>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={openSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setOpenSuccessMessage(false)}
      >
        <Alert
          onClose={() => setOpenSuccessMessage(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {i18n.t('screens.signin.successMessage')}
        </Alert>
      </Snackbar>
    </>
  );
}
