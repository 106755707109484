import React from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18n-js';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { AnalyticsProvider } from '../../../../providers';
import { Body, Row, Wrapper, useStyles } from './styles';

function OverlaysMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'overlays-popover' : undefined;

  const handleClick = (event) => {
    AnalyticsProvider.event('CLICK_OVERLAYS');
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickCategory = (category) => () => {
    AnalyticsProvider.event('CLICK_APPBAR_CATEGORY', { key: category });
    handleClose();
    history.push(`/template/${category}`);
  };

  return (
    <>
      <Typography
        className={classes.overlays}
        onClick={handleClick}
        variant="subtitle1"
      >
        {i18n.t('menu.toolbar.overlays')}
        <KeyboardArrowDownIcon />
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorReference="anchorPosition"
        onClose={handleClose}
        anchorPosition={{ top: 50, left: 0 }}
        PaperProps={{
          style: { width: '100%' },
        }}
      >
        <Wrapper>
          <Body>
            <Row>
              <Typography className={classes.title} variant="h6">
                {i18n.t('menu.categories.packs')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('animated')}
                variant="body1"
              >
                {i18n.t('menu.animated')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('ai')}
                variant="body1"
              >
                {i18n.t('menu.ai')}
              </Typography>
            </Row>
            <Row>
              <Typography className={classes.title} variant="h6">
                {i18n.t('menu.categories.time')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('countdown')}
                variant="body1"
              >
                {i18n.t('menu.countdown')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('countup')}
                variant="body1"
              >
                {i18n.t('menu.countup')}
              </Typography>
            </Row>
            <Row>
              <Typography className={classes.title} variant="h6">
                {i18n.t('menu.categories.scoreboards')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('soccer')}
                variant="body1"
              >
                {i18n.t('menu.soccer')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('basketball')}
                variant="body1"
              >
                {i18n.t('menu.basketball')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('baseball')}
                variant="body1"
              >
                {i18n.t('menu.baseball')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('padel')}
                variant="body1"
              >
                {i18n.t('menu.padel')}
              </Typography>
            </Row>
            <Row>
              <Typography className={classes.title} variant="h6">
                {i18n.t('menu.categories.engagement')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('alertbox')}
                variant="body1"
              >
                {i18n.t('menu.alertbox')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('chat')}
                variant="body1"
              >
                {i18n.t('menu.chat')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('question')}
                variant="body1"
              >
                {i18n.t('menu.question')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('medialoop')}
                variant="body1"
              >
                {i18n.t('menu.medialoop')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('giveaway')}
                variant="body1"
              >
                {i18n.t('menu.giveaway')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('roulette')}
                variant="body1"
              >
                {i18n.t('menu.roulette')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('coinflip')}
                variant="body1"
              >
                {i18n.t('menu.coinflip')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('confetti')}
                variant="body1"
              >
                {i18n.t('menu.confetti')}
              </Typography>
            </Row>
            <Row>
              <Typography className={classes.title} variant="h6">
                {i18n.t('menu.categories.gaming')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('controller')}
                variant="body1"
              >
                {i18n.t('menu.controller')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('fortnite')}
                variant="body1"
              >
                {i18n.t('menu.fortnite')}
              </Typography>
            </Row>
            <Row>
              <Typography className={classes.title} variant="h6">
                {i18n.t('menu.categories.games')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('wordguessing')}
                variant="body1"
              >
                {i18n.t('menu.wordguessing')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('quiz')}
                variant="body1"
              >
                {i18n.t('menu.quiz')}
              </Typography>
            </Row>
            {/* <Row>
              <Typography className={classes.title} variant="h6">
                {i18n.t('menu.categories.partnerships')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('pampling')}
                variant="body1"
              >
                {i18n.t('menu.pampling')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('gbest')}
                variant="body1"
              >
                {i18n.t('menu.gbest')}
              </Typography>
              <Typography
                className={classes.category}
                onClick={onClickCategory('ore')}
                variant="body1"
              >
                {i18n.t('menu.ore')}
              </Typography>
            </Row> */}
          </Body>
        </Wrapper>
      </Popover>
    </>
  );
}

export default OverlaysMenu;
