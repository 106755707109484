import { styled as styledM, alpha } from '@mui/material/styles';
import styled from 'styled-components';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';

export const AppBarStyled = styled(AppBar)`
  align-items: center;
  display: flex;
`;
AppBarStyled.displayName = 'AppBarStyled';

export const Body = styled('div')`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;
Body.displayName = 'Body';

export const LogoImage = styled('img')`
  cursor: pointer;
  width: 170px;
`;
LogoImage.displayName = 'LogoImage';

export const LinkStyled = styled(Link)`
  display: flex;
`;
LinkStyled.displayName = 'LinkStyled';

export const SupportStyled = styled(Button)`
  background-color: #ffdd00;
  color: #000000;
  margin-right: 8px;
  text-transform: none;

  &:hover {
    background-color: #E6C200;
  }
`;
SupportStyled.displayName = 'SupportStyled';

export const NavBarItem = styledM(Typography)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export const Search = styledM('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(1),
  width: 'auto',
}));

export const SearchIconWrapper = styledM('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styledM(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const ToolbarStyled = styled(Toolbar)`
  min-height: 50px;
  max-width: 1200px;
  width: 100%;
`;
ToolbarStyled.displayName = 'ToolbarStyled';
