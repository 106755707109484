import styled from 'styled-components';

export const AvatarContainer = styled('div')`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 30px;
  color: #d4af37;
  cursor: pointer;
  display: flex;
  padding-right: 5px;
`;
AvatarContainer.displayName = 'AvatarContainer';
