import { createContext, useContext } from 'react';

export const LoginStateContext = createContext();

export function useLoginState() {
  const context = useContext(LoginStateContext);
  if (context === undefined) {
    throw new Error('useLoginState must be used within a LogicProvider');
  }
  return context;
}
