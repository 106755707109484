import {
  SUBATHON_ERROR,
  SUBATHON_REQUEST,
  SUBATHON_RESET,
  SUBATHON_SUCCESS,
} from './types';

export const initialState = {
  loading: false,
  error: null,
  data: {
    overlay: {},
    user: {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUBATHON_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SUBATHON_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SUBATHON_RESET:
      return {
        ...initialState,
      };
    case SUBATHON_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        loading: false,
      };
    default:
      return state;
  }
}
