import { AUTH_ERROR, AUTH_REQUEST, AUTH_SUCCESS } from './types';

const initialState = {
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_ERROR:
      return {
        error: action.error,
        loading: false,
      };
    case AUTH_REQUEST:
      return {
        error: null,
        loading: true,
      };
    case AUTH_SUCCESS:
      return {
        error: null,
        loading: false,
      };
    default:
      return state;
  }
}
