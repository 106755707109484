import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 400px;
`;
Header.displayName = 'Header';

export const Title = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-left: 48px;
`;
Title.displayName = 'Title';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '400px',
  },
  list: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
