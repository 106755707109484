import {
  OVERLAYS_ERROR,
  OVERLAYS_REQUEST,
  OVERLAYS_RESET,
  OVERLAYS_SUCCESS,
} from './types';

export const initialState = {
  loading: false,
  error: null,
  data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OVERLAYS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case OVERLAYS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case OVERLAYS_RESET:
      return {
        ...initialState,
      };
    case OVERLAYS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
