import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './Auth/reducer';
import builderReducer from './Builder/reducer';
import overlayReducer from './Overlay/reducer';
import overlaysReducer from './Overlays/reducer';
import subathonReducer from './Subathon/reducer';
import userReducer from './User/reducer';

const rootReducer = {
  auth: authReducer,
  builder: builderReducer,
  overlay: overlayReducer,
  overlays: overlaysReducer,
  subathon: subathonReducer,
  user: userReducer,
};

const middleware = [
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
].filter(Boolean);

const store = createStore(combineReducers(rootReducer), compose(...middleware));

export default store;
