import React from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useStyles } from './styles';

export default function Loading({ isOpen }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

Loading.propTypes = {
  isOpen: PropTypes.bool,
};

Loading.defaultProps = {
  isOpen: false,
};
