import i18n from 'i18n-js';

import { FetchProvider, SocketProvider } from '../../providers';

import {
  OVERLAY_ERROR,
  OVERLAY_REQUEST,
  OVERLAY_RESET,
  OVERLAY_SET_AD,
  OVERLAY_SET_ALERT,
  OVERLAY_REMOVE_ALERT,
  OVERLAY_SUCCESS,
} from './types';

export const overlayError = (error) => ({
  type: OVERLAY_ERROR,
  error,
});

export const overlayRequest = {
  type: OVERLAY_REQUEST,
};

export const overlayReset = {
  type: OVERLAY_RESET,
};

export const overlaySetAd = (data) => ({
  type: OVERLAY_SET_AD,
  payload: data,
});

export const overlaySetAlert = (data) => ({
  type: OVERLAY_SET_ALERT,
  payload: data,
});

export const overlayRemoveAlert = (alertId) => ({
  type: OVERLAY_REMOVE_ALERT,
  payload: alertId,
});

export const overlaySuccess = (data) => ({
  type: OVERLAY_SUCCESS,
  payload: data,
});

export const getOverlay = (id) => async (dispatch) => {
  dispatch(overlayRequest);
  try {
    const result = await FetchProvider.fetchGet(`overlay/public/${id}`);

    await dispatch(overlaySuccess(result));

    await SocketProvider.connect(result._id);
    SocketProvider.listen('overlayChange', async (data) => {
      await dispatch(overlaySuccess(data));
    });
    SocketProvider.listen('builderChange', async (data) => {
      await dispatch(overlaySuccess(data));
    });
    SocketProvider.listen('showAd', async (data) => {
      await dispatch(overlaySetAd(data));
      setTimeout(() => {
        dispatch(overlaySetAd(null));
      }, data.adTime * 1000);
    });
    SocketProvider.listen('alert', async (data) => {
      await dispatch(overlaySetAlert(data));
    });
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(overlayError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400 || e?.response?.status === 404)
      return dispatch(overlayError(i18n.t('screens.builder.notFound')));

    return dispatch(overlayError(i18n.t('screens.builder.notFound')));
  }
};
