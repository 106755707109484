import {
  OVERLAY_ERROR,
  OVERLAY_REQUEST,
  OVERLAY_RESET,
  OVERLAY_SET_AD,
  OVERLAY_SET_ALERT,
  OVERLAY_REMOVE_ALERT,
  OVERLAY_SUCCESS,
} from './types';

export const initialState = {
  loading: false,
  error: null,
  data: {},
  ad: null,
  alerts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OVERLAY_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case OVERLAY_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case OVERLAY_RESET:
      return {
        ...initialState,
      };
    case OVERLAY_SET_AD:
      return {
        ...state,
        ad: action.payload,
      };
    case OVERLAY_SET_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case OVERLAY_REMOVE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload),
      };
    case OVERLAY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
