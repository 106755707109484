import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  padding: 0 24px 0 16px;
  width: 100%;
`;
Body.displayName = 'Body';

export const Row = styled.div`
  margin: 16px 0;
  width: 192px;
`;
Row.displayName = 'Row';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
Wrapper.displayName = 'Wrapper';

export const useStyles = makeStyles((theme) => ({
  category: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  overlays: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 50,
    margin: theme.spacing(0, 2),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
}));
