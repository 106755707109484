import i18n from 'i18n-js';

import { FetchProvider } from '../../providers';

import {
  OVERLAYS_ERROR,
  OVERLAYS_REQUEST,
  OVERLAYS_RESET,
  OVERLAYS_SUCCESS,
} from './types';

export const overlaysError = (error) => ({
  type: OVERLAYS_ERROR,
  error,
});

export const overlaysRequest = {
  type: OVERLAYS_REQUEST,
};

export const overlaysReset = {
  type: OVERLAYS_RESET,
};

export const overlaysSuccess = (data) => ({
  type: OVERLAYS_SUCCESS,
  payload: data,
});

export const getOverlays = () => async (dispatch) => {
  dispatch(overlaysRequest);
  try {
    const result = await FetchProvider.fetchGet('overlays');

    return await dispatch(overlaysSuccess(result));
  } catch (e) {
    if (!e?.response)
      return dispatch(overlaysError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400)
      return dispatch(overlaysError(i18n.t('screens.dashboard.errorFetching')));
    return dispatch(overlaysError(i18n.t('screens.dashboard.errorFetching')));
  }
};

export const removeOverlay = (id) => async (dispatch, getState) => {
  try {
    const result = await FetchProvider.fetchDelete(`overlay/${id}`);

    if (result.removed) {
      const { overlays } = getState();
      const filtered = overlays.data.filter((overlay) => overlay._id !== id);
      await dispatch(overlaysSuccess(filtered));
    }
    return;
  } catch (e) {
    if (!e?.response)
      return dispatch(overlaysError(i18n.t('common.connectionError')));
    if (e?.response?.status === 400 || e?.response?.status === 404)
      return dispatch(overlaysError(i18n.t('screens.dashboard.errorDeleting')));
    return dispatch(overlaysError(i18n.t('screens.dashboard.notDeleted')));
  }
};
