import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import i18n from 'i18n-js';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { createGlobalStyle } from 'styled-components';

import store from './reducers';
import AnalyticsProvider from './providers/analytics';
import locales from './constants/locales';
import { DARK_MODE_THEME } from './constants/theme';
import Loading from './components/Loading';
import Menu from './components/Menu';
import PrivateRoute from './components/PrivateRoute';

i18n.locale = navigator.language || navigator.userLanguage;
i18n.defaultLocale = 'en';
i18n.fallbacks = true;
i18n.translations = locales;

const Builder = React.lazy(() => import('./pages/Builder'));
const Channel = React.lazy(() => import('./pages/Channel'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const LoginPage = React.lazy(() => import('./pages/Login'));
const MyOverlays = React.lazy(() => import('./pages/MyOverlays'));
const Overlay = React.lazy(() => import('./pages/Overlay'));
const Revenue = React.lazy(() => import('./pages/Revenue'));
const PayoutSettings = React.lazy(() => import('./pages/PayoutSettings'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Ocoins = React.lazy(() => import('./pages/Ocoins'));
const Subscription = React.lazy(() => import('./pages/Subscription'));
const Streamdeck = React.lazy(() => import('./pages/Streamdeck'));
const Subathon = React.lazy(() => import('./pages/Subathon'));
const Templates = React.lazy(() => import('./pages/Templates'));
const Wallet = React.lazy(() => import('./pages/Wallet'));

const NotShowGDPRMessage = createGlobalStyle`
  .google-revocation-link-placeholder {
    display: none !important;
  }

  body > div.fc-consent-root {
    display: none !important;
  }
`;

function App() {
  useEffect(() => {
    AnalyticsProvider.initialize();
  }, []);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
          ...DARK_MODE_THEME,
        },
      }),
    []
  );

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Suspense fallback={<Loading isOpen />}>
              <Switch>
                <Route path="/overlay/:overlayId">
                  <NotShowGDPRMessage />
                  <Overlay />
                </Route>
                <Route path="/subathon/:userName/:overlayId">
                  <Subathon />
                </Route>
                <Route path="/">
                  <Menu>
                    <Switch>
                      <Route path="/dashboard">
                        <Dashboard />
                      </Route>
                      <Route path="/login/:token">
                        <LoginPage />
                      </Route>
                      <PrivateRoute path="/my-overlays">
                        <MyOverlays />
                      </PrivateRoute>
                      <Route
                        path={[
                          '/templates/:search',
                          '/templates',
                          '/template/:group',
                        ]}
                      >
                        <Templates />
                      </Route>
                      <PrivateRoute path="/builder/:overlayId">
                        <Builder />
                      </PrivateRoute>
                      <PrivateRoute path="/streamdeck/:overlayId">
                        <Streamdeck />
                      </PrivateRoute>
                      <PrivateRoute path="/channel">
                        <Channel />
                      </PrivateRoute>
                      <PrivateRoute path="/settings">
                        <Settings />
                      </PrivateRoute>
                      <Route path={['/subscription/:status', '/subscription']}>
                        <Subscription />
                      </Route>
                      <Route path={['/ocoins/:status', '/ocoins']}>
                        <Ocoins />
                      </Route>
                      <Route path={'/revenue'}>
                        <Revenue />
                      </Route>
                      <PrivateRoute path={'/payout-settings'}>
                        <PayoutSettings />
                      </PrivateRoute>
                      <PrivateRoute path={'/wallet'}>
                        <Wallet />
                      </PrivateRoute>
                      <Route>
                        <Redirect to="/dashboard" />
                      </Route>
                    </Switch>
                  </Menu>
                </Route>
              </Switch>
            </Suspense>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
