import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';

export default function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const haveToken =
    !!localStorage.getItem('token') || !!sessionStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={() => {
        return haveToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
