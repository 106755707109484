import mergeDeep from '../../utils/mergeDeep';
import {
  BUILDER_ERROR,
  BUILDER_REQUEST,
  BUILDER_RESET,
  BUILDER_SET,
  BUILDER_SET_CHAT,
  BUILDER_SUCCESS,
  BUILDER_UPDATED,
} from './types';

export const initialState = {
  updated: false,
  loading: false,
  error: null,
  data: {},
  chat: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BUILDER_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case BUILDER_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case BUILDER_RESET:
      return {
        ...initialState,
      };
    case BUILDER_SET:
      return {
        ...state,
        data: mergeDeep(state.data, action.payload),
        loading: false,
      };
    case BUILDER_SET_CHAT:
      const newChat = [...state.chat, action.payload];
      if (newChat.length > 100) {
        newChat.shift();
      }

      return {
        ...state,
        chat: newChat,
      };
    case BUILDER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case BUILDER_UPDATED:
      return {
        ...state,
        loading: false,
        updated: action.payload,
      };
    default:
      return state;
  }
}
