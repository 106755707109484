import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';

export const Container = styled.div`
  align-items: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
Container.displayName = 'Container';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 424px;
`;
Form.displayName = 'Form';

export const FormControlLabelStyled = styled(FormControlLabel)`
  color: white;

  & path {
    fill: ${({ $hasError }) => ($hasError ? '#FF7979' : '#675ce7')};
  }
`;
FormControlLabelStyled.displayName = 'FormControlLabelStyled';

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 424px;
`;
Header.displayName = 'Header';

export const RequiredField = styled.p`
  color: #b4bacf;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 16px;
  margin: 12px 0 4px;

  & strong {
    color: #675ce7;
  }
`;
RequiredField.displayName = 'RequiredField';

export const TermsLink = styled.a`
  color: #675ce7;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 24px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
TermsLink.displayName = 'TermsLink';

export const useStyles = makeStyles((theme) => ({
  alert: {
    margin: '16px 0 16px',
  },
  checkbox: {
    color: 'white',
    marginTop: 8,
    '& path': {
      fill: '#675CE7',
    },
  },
  link: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    '& strong': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  required: {
    '& strong': {
      color: theme.palette.primary.main,
    },
  },
  submit: {
    height: 56,
    margin: theme.spacing(3, 0, 2),
  },
}));
